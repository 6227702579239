import React from "react";
import pageNotFound from "./assets/404.png";

const styles = {
  width: "100%",
  "text-align": "center",
};

export const PageNotFound = () => {
  return (
    <div style={styles}>
      <img src={pageNotFound} alt="page-not-found" />
    </div>
  );
};
